import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import SEO from '../components/seo'

const IndexPage = () => (
  <>
    <SEO title="Privacy Policy" />
    <article className="basicPageContainer">
      <h1>Privacy Policy</h1>
      <div className="info">
        <p>
          This Privacy Policy is meant to help you understand what data we, Redfin
          Solutions, LLC (“Redfin”, "us," "we," or "our,") collect, why we collect it, and
          what we do with it in connection with the use of our website
          (redfinsolutions.com, the “Site”), services and applications (collectively, the
          “Services”). If you have any questions about this Privacy Policy (the “Privacy
          Policy”) or Redfin's data collection, use, and disclosure practices, please
          contact us at{' '}
          <a
            href="mailto:privacy@redfinsolutions.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy@redfinsolutions.com
          </a>
          .
        </p>
        <h2 dir="ltr">Information We Collect</h2>
        <p dir="ltr">
          We collect information on our site that helps us learn what users are most
          interested in, which helps us improve our offerings.
        </p>
        <p>
          We collect information in the following ways:
          <br />
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Information you give us. For example, you may choose to contact us through
              our Site or directly via e-mail. When you do, we’ll ask for{' '}
              <a href="#pi">personal information</a>, like your name and e-mail address.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Information we get from you interacting with our Site and Services:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  When you view content on our Site, we automatically collect and store
                  certain information in server logs. This includes:
                </p>
                <ul>
                  <li dir="ltr">
                    <p dir="ltr">
                      <a href="#ip">Internet protocol address</a>
                    </p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">URL(s) visited</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Browser &amp; OS type</p>
                  </li>
                </ul>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  We use various technologies to collect and store information when you
                  visit including using{' '}
                  <a href="#cookies">cookies or similar technologies</a> to identify your
                  browser or device and analyze the traffic to our website.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  We use Google Analytics that uses cookies to collect non-identifying
                  information.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  We may also use clear GIFs (a.k.a. web{' '}
                  <a href="#beacons">“beacons” or “pixels”</a>) on site and in HTML-based
                  emails sent to our users to track which emails are opened by recipients.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Your name and e-mail address when you subscribe to, for example, our
                  newsletter.
                </p>
              </li>
            </ul>
          </li>
        </ul>

        <p>&nbsp;</p>

        <h2 dir="ltr">How We Use Information We Collect</h2>

        <p dir="ltr">
          We use information collected to improve your<a href="#ux"> user experience</a>{' '}
          and the overall quality of our services. One of the products we use to do this
          is Google Analytics. For example, by saving your language preferences, we’ll be
          able to have our services appear in the language you prefer. Google provides
          some additional privacy options regarding its Analytics cookies on{' '}
          <OutboundLink
            href="http://www.google.com/policies/privacy/partners/"
            target="_blank"
            rel="noopener noreferrer"
          >
            its privacy page
          </OutboundLink>
          .
        </p>

        <p dir="ltr">
          We may send you informational and promotional e-mails from time to time. If you
          wish to subscribe to our newsletter(s), we will use your name and email address
          to send the newsletter to you. Out of respect for your privacy, we provide you a
          way to unsubscribe. Please see the “Choice and Opt-out” section.
        </p>

        <p>
          We will also send you strictly service-related announcements on rare occasions
          when it is necessary to do so. For instance, if any of our Services are
          temporarily suspended for maintenance, we might send you an email. Generally,
          you may not opt-out of these communications, which are not promotional in
          nature. If you do not wish to receive them, contact{' '}
          <a
            href="mailto:privacy@redfinsolutions.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy@redfinsolutions.com
          </a>
          .
        </p>

        <p dir="ltr">
          We will ask for your consent before using information for a purpose other than
          those that are set out in this Privacy Policy.
        </p>

        <p dir="ltr">
          We may process your <a href="#pi">personal information</a> on a server located
          outside the country where you live.
        </p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Information We Share</h2>

        <p dir="ltr">
          We do not share <a href="#pi">personal information</a> with companies,
          organizations and individuals outside of Redfin unless one of the following
          circumstances applies:
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">With your consent</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">For external processing</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">For legal reasons</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              We will share <a href="#pi">personal information</a> with companies,
              organizations or individuals outside of Redfin if we have a good-faith
              belief that access, use, preservation or disclosure of the information is
              reasonably necessary to:
            </p>

            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  meet any applicable law, regulation, legal process or enforceable
                  governmental request.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  enforce applicable Terms of Service, including investigation of
                  potential violations.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  detect, prevent, or otherwise address fraud, security or technical
                  issues.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  protect against harm to the rights, property or safety of Redfin.
                </p>
              </li>
            </ul>
          </li>
        </ul>

        <h2 dir="ltr">&nbsp;</h2>

        <h2 dir="ltr">Information Security</h2>

        <p dir="ltr">
          We work hard to protect Redfin and our users from unauthorized access to or
          unauthorized alteration, disclosure or destruction of information we hold. In
          particular:
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              We encrypt our website <a href="#ssl">using SSL</a>.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              We review our information collection, storage and processing practices,
              including physical security measures, to guard against unauthorized access
              to systems.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              We restrict access to <a href="#pi">personal information</a> to Redfin
              employees, contractors and agents who need to know that information in order
              to process it for us, and who are subject to strict contractual
              confidentiality obligations and may be disciplined or terminated if they
              fail to meet these obligations.
            </p>
          </li>
        </ul>

        <p dir="ltr">
          The measures we use are designed to provide a level of security appropriate to
          the risk of processing your <a href="#pi">personal information</a>. However,
          please bear in mind that the Internet cannot be guaranteed to be 100% secure.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">Children's Privacy</h2>

        <p dir="ltr">
          Redfin recognizes the privacy interests of children and we encourage parents and
          guardians to take an active role in their children’s online activities and
          interests. This Site is not intended for children under the age of 13. Redfin
          does not target its services or this Site to children under 13. Redfin does not
          knowingly collect personally identifiable information from children under the
          age of 13.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">When This Privacy Policy Applies</h2>

        <p dir="ltr">
          Our Privacy Policy applies to all of the services offered by Redfin Solutions
          LLC, but excludes services that have separate privacy policies that do not
          incorporate this Privacy Policy.
        </p>

        <p dir="ltr">
          Our Privacy Policy does not apply to services offered by other companies or
          individuals, including other sites linked from our services. Our Privacy Policy
          does not cover the information practices of other companies and organizations
          who advertise our services, and who may use cookies, pixel tags and other
          technologies to serve and offer relevant ads.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">Compliance and Cooperation with Regulatory Authorities</h2>

        <p dir="ltr">
          We regularly review our compliance with our Privacy Policy. When we receive
          formal written complaints, we will contact the person who made the complaint to
          follow up. We work with the appropriate regulatory authorities, including local
          data protection authorities, to resolve any complaints regarding the transfer of
          personal data that we cannot resolve with our users directly.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">
          Your Data Protection Rights Under the General Data Protections Regulation (GDPR)
        </h2>

        <p dir="ltr">
          If you are a resident of the European Economic Area (EEA), you have the
          following data protection rights:
          <br />
          &nbsp;
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              If you wish to access, correct, update, or request deletion of your{' '}
              <a href="#pi">personal information</a>, you can do so at any time by
              emailing{' '}
              <a
                href="mailto:privacy@redfinsolutions.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy@redfinsolutions.com
              </a>
              .
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              In addition, you can object to the processing of your{' '}
              <a href="#pi">personal information</a>, ask us to restrict the processing of
              your personal information, or request portability of your personal
              information. Again, you can exercise these rights by emailing{' '}
              <a
                href="mailto:privacy@redfinsolutions.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy@redfinsolutions.com
              </a>
              .
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              You have the right to opt-out of marketing communications we send you at any
              time. You can exercise this right by clicking on the "unsubscribe" or
              "opt-out" link in the marketing emails we send you. To opt-out of other
              forms of marketing, please contact us by emailing{' '}
              <a
                href="mailto:privacy@redfinsolutions.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy@redfinsolutions.com
              </a>
              .
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Similarly, if we have collected and process your{' '}
              <a href="#pi">personal information</a> with your consent, then you can
              withdraw your consent at any time. &nbsp;Withdrawing your consent will not
              affect the lawfulness of any processing we conducted prior to your
              withdrawal, nor will it affect the processing of your{' '}
              <a href="#pi">personal information</a> conducted in reliance on lawful
              processing grounds other than consent.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              You have the right to complain to a data protection authority about our
              collection and use of your <a href="#pi">personal information</a>. For more
              information, please contact your local data protection authority.
            </p>
          </li>
        </ul>

        <p dir="ltr">
          We respond to all requests we receive from individuals wishing to exercise their
          data protection rights in accordance with applicable data protection laws.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">Employment Opportunities</h2>

        <p dir="ltr">
          We provide you with a means for submitting your resume or other personally
          identifiable information through the Site for consideration for employment
          opportunities at Redfin. Personally identifiable information received through
          resume submissions will be kept confidential. We may contact you for additional
          information to supplement your resume, and we may use your personally
          identifiable information within Redfin, or keep it on file for future use, as we
          make our hiring decisions.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">Customer Service</h2>

        <p dir="ltr">
          Based upon the personally identifiable information you provide us, we will send
          you a welcoming email to verify your username and password. We will also
          communicate with you in response to your inquiries, to provide the services you
          request, and to manage your account. We will communicate with you by email or
          telephone, in accordance with your wishes.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">Choice/Opt-out</h2>

        <p dir="ltr">
          You may set your browser to block all cookies, including cookies associated with
          our Site, or to indicate when a cookie is being set by us.
        </p>

        <p dir="ltr">
          To request updates or changes to your information or your preferences regarding
          receiving future promotional messages from us, you may contact us using the
          information in the Contact Us section of this Privacy Policy or follow the
          opt-out instructions that are contained in the bottom of the email communication
          you received.
        </p>

        <p dir="ltr">
          You will be notified prior to when your personally identifiable information is
          collected by any third party that is not our agent/service provider, so you can
          make an informed choice as to whether or not to share your information with that
          party.
        </p>

        <p dir="ltr">
          Please note that if you opt out of receiving our promotional or marketing
          emails, you may still receive certain service-related communications from us,
          such as administrative and services announcements and messages about your
          account.
        </p>

        <p dir="ltr">&nbsp;</p>

        <h2 dir="ltr">Changes to our Privacy Policy</h2>

        <p dir="ltr">
          If we decide to change our privacy policy, we will post those changes on this
          page, and/or update the Privacy Policy modification date below.
        </p>

        <p>This policy was last modified on May 22, 2014</p>

        <p>&nbsp;</p>

        <h2 dir="ltr">Contact Us</h2>

        <p dir="ltr">
          You may choose to contact us with privacy and other related inquiries via e-mail
          at{' '}
          <a
            href="mailto:privacy@redfinsolutions.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy@redfinsolutions.com
          </a>{' '}
          or by phone at 908-437-8725.
        </p>

        <p dir="ltr">&nbsp;</p>

        <p dir="ltr"></p>

        <h2>Key Terms</h2>

        <p>&nbsp;</p>

        <h3 dir="ltr" id="ux">
          User Experience
        </h3>

        <p dir="ltr">
          For example, cookies allow us to analyze how users interact with our site. That
          analysis may reveal that a visitors take too many steps to contact us, or it
          allows us to compare design solutions for their ease of use. We then use that
          information to optimize our services.
        </p>

        <h3 dir="ltr" id="cookies">
          Cookies and Similar Technologies
        </h3>

        <p dir="ltr">
          A cookie is a small file containing a string of characters that is sent to your
          computer when you visit a website. When you visit the website again, the cookie
          allows that site to recognize your browser. Cookies may store user preferences
          and other information. You can reset your browser to refuse all cookies or to
          indicate when a cookie is being sent.
        </p>

        <h3 dir="ltr" id="ip">
          IP Address
        </h3>

        <p dir="ltr">
          Every device connected to the Internet is assigned a number known as an Internet
          protocol (IP) address. These numbers are usually assigned in geographic blocks.
          An IP address can often be used to identify the location from which a device is
          connecting to the Internet.
        </p>

        <h3 dir="ltr">Non-Personally Identifiable Information</h3>

        <p dir="ltr">
          This is information that is recorded about users so that it no longer reflects
          or references an individually identifiable user.
        </p>

        <h3 dir="ltr" id="pi">
          Personal Information
        </h3>

        <p dir="ltr">
          This is information which you provide to us which personally identifies you,
          such as your name, email address.
        </p>

        <h3 dir="ltr" id="beacons">
          Beacons or Pixels
        </h3>

        <p dir="ltr">
          A pixel or beacon is a type of technology placed on a website or within the body
          of an email for the purpose of tracking activity on websites, or when emails are
          opened or accessed, and is often used in combination with cookies.
        </p>

        <h3 dir="ltr" id="logs">
          Server logs
        </h3>

        <p dir="ltr">
          Like most websites, our servers automatically record the page requests made when
          you visit our sites. These “server logs” typically include your web request,
          Internet Protocol address, browser type, browser language, the date and time of
          your request and one or more cookies that may uniquely identify your browser.
        </p>

        <p dir="ltr">
          Here is an example of a typical access log entry when someone visits our site,
          followed by a breakdown of its parts:
        </p>

        <p dir="ltr">
          example.com:80 12.34.56.789 - - [09/May/2018:06:15:56 +0000] "GET /user/register
          HTTP/1.1" 200 852 "-" "Mozilla/5.0 (compatible; bingbot/2.0; +
          <OutboundLink
            href="http://www.bing.com/bingbot.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.bing.com/bingbot.htm
          </OutboundLink>
          )"
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">Example.com:80 is the website on which the page was viewed.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              12.34.56.789 is the <a href="#ip">ip address</a> assigned to the user by the
              user’s ISP; depending on the user’s service, a different{' '}
              <a href="#ip">ip address</a> may be assigned to the user by their service
              provider each time they connect to the Internet;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              - The first "hyphen" in the output indicates that the requested piece of
              information is not available. In this case, the information that is not
              available is the RFC 1413 identity of the client determined by identd on the
              clients machine.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              - The second “hyphen” in the output indicates that the requested piece of
              information is not available. In this case, it is the userid of the person
              requesting the document as determined by HTTP authentication and indicates
              that the page is not password protected.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              [09/May/2018:06:15:56 +0000] is the date and time of the query;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              GET /user/register HTTP/1.1 is the requested URL and the method by which it
              was requested;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              200 is the status code that the server sends back to the client. This
              information is very valuable, because it reveals whether the request
              resulted in a successful response (codes beginning in 2), a redirection
              (codes beginning in 3), an error caused by the client (codes beginning in
              4), or an error in the server (codes beginning in 5). The full list of
              possible status codes can be found in the{' '}
              <OutboundLink
                href="http://www.w3.org/Protocols/rfc2616/rfc2616.txt"
                target="_blank"
                rel="noopener noreferrer"
              >
                HTTP specification
              </OutboundLink>{' '}
              (RFC2616 section 10).]
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">852 is the number of bytes sent, including headers.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like
              Gecko) Chrome/63.0.3239.132 Safari/537.36 is the browser and operating
              system being used; and
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              "-" is the referrer on all requests. In this case, no referrer was listed
              because the page resulted in a 200 status code.
            </p>
          </li>
        </ul>

        <h3 dir="ltr" id="ssl">
          SSL
        </h3>

        <p dir="ltr">
          SSL encrypts the connection between your computer and Google. This helps prevent
          others, like Internet cafes, Internet Service Providers, and Wi-Fi hotspots,
          from seeing your search results page and login information.
        </p>
      </div>
    </article>
  </>
)

export default IndexPage
